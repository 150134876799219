import React, { useState } from "react"
import { Link } from "gatsby"
import { data } from "./data"
import styled from "@emotion/styled"
import { Box, Flex } from "@rebass/grid/emotion"
import classNames from "classnames"
import theme from "../../../../../nutrafol-ui-kit/styles/theme"
import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"
import { queries } from "@nutrafol/nutrafol-ui-kit/styles/mediaqueries"
import { TextElementMen } from "@nutrafol/nutrafol-ui-kit/dist/TextElementMen"
import { ButtonWildMen } from "@nutrafol/nutrafol-ui-kit/dist/ButtonWildMen"
import { navigate } from "@reach/router"
import { isIpad } from "../../../../utils/general"
import NavigationShopBanner from "../concierge/navigationshop-banner-variant"

import PlusIcon from "../../../../assets/icons/nova/plus-small.svg"
import MinusIcon from "../../../../assets/icons/nova/minus.svg"

const Nav = styled.nav`
  display: none;

  ${queries.large`
    display: inline-block;
    vertical-align: top;
    position: static;
    width: 100%;
    height: auto;
    padding: 0;
    .navigation-logo {
      display: none;
    }
  `}
  //for debugging
    [aria-expanded='true'] {
    //border: 1px orange solid;
  }

  [aria-expanded="false"] {
    //border: 1px green solid;
  }
`

const SubItemWrapper = styled(Box)`
  width: 100%;
  height: 100%;
`

const SubItem = styled(Box)`
  height: 100%;
  background-color: #f8f8f5;
  border-radius: 4px;
  padding: 16px 0;
  box-sizing: border-box;
  &:hover {
    background-color: #d8d8d8;
  }
  .subtext {
    margin: auto;
    max-width: 194px;
  }

  min-height: 286px;

  > div {
    min-height: 254px;
  }
`

const NavUl = styled.ul`
  display: flex;
  //gap: 40px;
`

const ListItem = styled.li`
  @media (min-width: 1024px) {
    a {
      padding: 20px 20px;
      //border: 1px red dotted;
    }

    position: relative;
    display: inline-block;

    .minus {
      display: none;
    }

    .plus {
      display: inline-block;
    }

    &#products {
      //padding-left: 24px;
    }

    .link-text {
      display: inline-block;
      width: 53px;

      &.products {
        width: 66px;
      }

      &.faq {
        width: 32px;
      }
    }

    &:focus,
    &:hover,
    &.hover {
      .link-text {
        font-family:
          Dinamo Bold,
          Helvetica,
          Arial,
          sans-serif;
      }
    }

    &.has-subnav {
      &:focus,
      &.hover {
        .dropdown {
          visibility: visible;
          opacity: 1;
        }

        .minus {
          display: inline-block;
        }

        .plus {
          display: none;
        }
      }
    }
  }
`

const InternalNavLink = styled.a`
  display: inline-block;
  cursor: default !important;
  &:hover {
    border-bottom: 1px solid #ffffff;
  }
`

const GatsbyNavLink = styled(Link)`
  display: inline-block;
`

const ArrowSection = styled.span`
  display: inline-block;
  //width: 8px;
  transform: translate(0, 2px);
`

const DropdownMenu = styled.div`
  visibility: hidden;
  opacity: 0;
  //visibility: visible;
  //opacity: 1;

  border-left: 1px solid ${theme.wild.color.nova.transparency.black[20]};
  border-bottom: 1px solid ${theme.wild.color.nova.transparency.black[20]};
  border-right: 1px solid ${theme.wild.color.nova.transparency.black[20]};
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;

  position: absolute;
  top: 66px;
  background: #fff;
  z-index: 12;
  transition:
    visibility 0s,
    opacity 0.1s ease-in-out;
  left: -1px;

  //ransform: translateX(-50%);

  &.full-dropdown {
    width: min(calc(100vw - 64px), 1334px);
    transform: none;
  }
  &.dropdown-shop-desktop {
    .row {
      //max-width: 1333px;

      .box-left {
        .box-left-children {
          width: 100%;
        }
      }
    }
  }
`

const DropdownWrapper = styled(Flex)`
  flex-direction: row;
`

const MainBox = styled(Box)`
  a:focus,
  a:hover {
    .name-section {
      h4 {
        color: ${theme.wild.color.men.primary.default} !important;
        text-decoration: underline;
      }
    }
  }

  img {
    width: 67%;
    margin: auto;
    max-width: 217px;
    padding: 0;
  }

  .flex-row {
    gap: 8px;
    padding: 8px 8px;
  }
`

const NavItem = styled.div`
  //background: #464d52;

  &.product-page {
    background: #fff;
  }

  &.nav-item-0 {
  }

  &.nav-item-1 {
  }
  &.nav-item-2 {
  }

  height: 100%;
  position: relative;

  > a {
    padding: 0;
    display: block;
    transition: background-color 0.3s;
  }
`

const TitleIconBox = styled(Box)`
  text-align: center;

  .icon-wrap {
    display: inline-block;
    vertical-align: center;
    margin-right: 8px;

    img {
      width: 32px;
      height: 32px;
    }
  }

  .title-box {
    margin: auto;
    max-width: 134px;
    min-height: 36px;
    @media (min-width: 1240px) {
      max-width: 100%;
    }
  }

  h4 {
    display: inline-block;
    margin: auto;
    min-height: 44px;
    @media (min-width: 1240px) {
      min-height: auto;
    }
  }
`

const ShopButtonWrapper = styled(Box)`
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 100%;
  padding: 0 8px 8px 8px;

  @media (min-width: 1024px) {
    padding: 8px 8px 16px 8px;
  }

  .desktop-button {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    * {
      line-height: 1.4 !important;
    }

    &:hover {
      span {
        color: ${theme.wild.color.men.primary.secondary} !important;
      }
    }

    span {
      font-family: "Dinamo Regular";
      color: ${theme.wild.color.men.primary.default} !important;
      font-size: 16px !important;
      line-height: 16px !important;
    }

    .right {
      margin-left: 8px;

      svg {
        width: 13px;
        height: 11px;
      }
    }
  }
`

if (typeof window !== `undefined`) {
  // Polyfill for IE9+
  if (!window.Element.prototype.matches)
    window.Element.prototype.matches =
      window.Element.prototype.msMatchesSelector
  if (!window.Element.prototype.closest)
    window.Element.prototype.closest = function (selector) {
      var el = this
      while (el) {
        if (el.matches(selector)) {
          return el
        }
        el = el.parentElement
      }
    }
}

const addActiveItemClass = (el) => {
  if (el && el.classList && el.classList.contains("has-subnav")) {
    el.classList.add("hover")
    handleExpand(el)
  }
}

const removeActiveItemClass = (el, preventClick) => {
  const mouseLeftItem = hasHover(el)
  if (!!mouseLeftItem) {
    mouseLeftItem.classList.remove("hover")
  }
  if (preventClick) {
    el.preventDefault()
  }
  handleExpand(el)
}

const handleExpand = (el) => {
  if (el?.id) {
    ;[
      ...document.querySelectorAll("nav.navigation-variant [aria-expanded]"),
    ].forEach((a) => {
      const isHover = a?.parentElement?.classList?.contains("hover")
      a.ariaExpanded = isHover ? "true" : "false"
    })
  }
}

const onFocus = (el) => {
  handleExpand(el)
}

const onBlur = (el) => {
  handleExpand(el)
}

const removeAllActiveItemClass = (e) => {
  const hover = document.getElementsByClassName("hover")
  let i = 0
  for (; i < hover.length; i++) {
    hover[i].classList.remove("hover")
  }
}

const hasHover = (e) => e.target.closest(".has-subnav.hover")

const trackExpanded = (text) => {
  segmentEvent("Navigation Expanded", {
    module_location: "navigation",
    text,
  })
}

const trackClicked = (text, destination) => {
  segmentEvent("Navigation Clicked", {
    module_location: "navigation",
    type: "button",
    text,
    destination,
  })
}

//force navigate for ipad https://nutrafol.atlassian.net/browse/NT2-8512
const onTouchStart = (url) => {
  if (isIpad()) {
    navigate(url)
  }
}

const Navigation = ({ isProductPage }) => {
  const [, setHoveredItem] = useState(null)

  const navLinks = data.map((item, index) => {
    return (
      <React.Fragment key={`navLink-${index}`}>
        {!item.isMobileOnly && (
          <ListItem
            id={item.name}
            key={`navitem-${index}`}
            className={` ${!!item.children ? "has-subnav" : "has-link"}   ${
              item.name === "products" ? "full-dropdown-container" : ""
            } `}
            onMouseEnter={(e) => {
              if (!!item.children) {
                trackExpanded(item.link.text)
              }
              addActiveItemClass(e.currentTarget)
            }}
            onMouseLeave={(e) => removeActiveItemClass(e)}
            onClick={(e) => {
              if (!!item.children) {
                //trackExpanded(item.link.text)
              }
              addActiveItemClass(e.currentTarget)
            }}
            onFocus={(e) => onFocus(e.currentTarget)}
            onBlur={(e) => onBlur(e.currentTarget)}
            data-linkname={item.name}
          >
            {!!item.children ? (
              <>
                {/* we need href="" for ADA */}
                <InternalNavLink
                  href=""
                  role="button"
                  aria-expanded="false"
                  className={`list-item`}
                  onClick={(e) => removeActiveItemClass(e, true)}
                >
                  <Flex>
                    <Box>
                      <TextElementMen
                        element="span"
                        text={item.link.text}
                        className={`body--large text-men-secondary-secondary link-text text-center  ${item.link.text.toLowerCase()} `}
                      />
                    </Box>
                    <ArrowSection className={`plus`}>
                      <PlusIcon />
                    </ArrowSection>
                    <ArrowSection className={`minus`}>
                      <MinusIcon />
                    </ArrowSection>
                  </Flex>
                </InternalNavLink>
                <DropdownMenu
                  className={`dropdown ${
                    item.name === "products"
                      ? "full-dropdown dropdown-shop-desktop"
                      : ""
                  }`}
                >
                  <DropdownWrapper className="row">
                    <Box className={`box-left`}>
                      <MainBox>
                        {item.children && (
                          <Box className={`box-left-children`}>
                            <Flex
                              flexWrap="wrap"
                              flexDirection={"row"}
                              ml={"0"}
                              className={`flex-row`}
                            >
                              {item.children.map((subitem, idx) => {
                                const NavIcon = subitem?.icon
                                return (
                                  <Box
                                    flex={1}
                                    key={`nav-item-${idx}`}
                                    className="box-left-children__item"
                                    pt={0}
                                    px={0}
                                  >
                                    <NavItem
                                      className={classNames(`nav-item-${idx}`, {
                                        "product-page": isProductPage,
                                      })}
                                      onTouchStart={() =>
                                        onTouchStart(subitem.url)
                                      }
                                      // onClick={()=>onTouchStart(subitem.url)}
                                    >
                                      <Link
                                        to={subitem.url}
                                        key={`subitem-${idx}`}
                                        onClick={() => {
                                          trackClicked(
                                            subitem.name,
                                            subitem.url
                                          )
                                        }}
                                      >
                                        <SubItemWrapper
                                          onMouseEnter={() =>
                                            setHoveredItem(subitem.name)
                                          }
                                          onMouseLeave={() =>
                                            setHoveredItem(null)
                                          }
                                          onClick={removeActiveItemClass}
                                        >
                                          <SubItem>
                                            <Flex flexWrap="wrap">
                                              <TitleIconBox width={1} pt={0}>
                                                {subitem?.icon && (
                                                  <div className="icon-wrap">
                                                    <img
                                                      src={NavIcon}
                                                      alt={``}
                                                    />
                                                  </div>
                                                )}
                                                <Flex
                                                  alignItems={"center"}
                                                  className={`title-box`}
                                                >
                                                  <TextElementMen
                                                    element="h4"
                                                    text={subitem.title}
                                                    className={
                                                      "title--small text-master-base-default uppercase text-center"
                                                    }
                                                  />
                                                </Flex>
                                              </TitleIconBox>
                                              <Box
                                                width={1}
                                                pt={[16]}
                                                pb={[16]}
                                              >
                                                <img
                                                  srcSet={`
                                                  ${subitem.image}&w=262,
                                                  ${subitem.image}&w=350 1.5x,
                                                  ${subitem.image}&w=524 2x,
                                                  ${subitem.image}&w=1024 3x
                                                `}
                                                  src={`${subitem.image}&w=524`}
                                                  className={`dropdown-image ${subitem.safeName}`}
                                                  alt={subitem.safeName}
                                                />
                                              </Box>
                                              <Box
                                                flex={1}
                                                mt="auto"
                                                className={`subtext`}
                                              >
                                                {subitem.subtext ||
                                                subitem.subtextDesktop ? (
                                                  <TextElementMen
                                                    element="p"
                                                    text={
                                                      subitem.subtextDesktop
                                                        ? subitem.subtextDesktop
                                                        : subitem.subtext
                                                    }
                                                    className="body--small text-master-base-default text-center"
                                                  />
                                                ) : null}
                                              </Box>
                                            </Flex>
                                          </SubItem>
                                        </SubItemWrapper>
                                      </Link>
                                    </NavItem>
                                  </Box>
                                )
                              })}
                              <NavigationShopBanner
                                trackClicked={trackClicked}
                              />
                            </Flex>

                            <ShopButtonWrapper>
                              <ButtonWildMen
                                secondary
                                arrowRight
                                label="Shop All Products"
                                className="desktop-button capitalize"
                                trackObject={{
                                  destination: "/men/products/",
                                  name: "Navigation Clicked",
                                  module_location: "navigation",
                                }}
                                onClick={() => {
                                  navigate("/men/products/")
                                }}
                              />
                            </ShopButtonWrapper>
                          </Box>
                        )}
                      </MainBox>
                    </Box>
                  </DropdownWrapper>
                </DropdownMenu>
              </>
            ) : (
              <>
                {/* we need href="" for ADA */}
                <GatsbyNavLink
                  className={`list-item`}
                  to={item.link.url}
                  onClick={() => trackClicked(item.name, item.link.url)}
                  role="button"
                >
                  <TextElementMen
                    element="span"
                    text={item.link.text}
                    className={`body--large text-men-secondary-secondary link-text text-center ${item.link.text.toLowerCase()}`}
                  />
                </GatsbyNavLink>
              </>
            )}
          </ListItem>
        )}
      </React.Fragment>
    )
  })

  return (
    <Nav className="navigation navigation-variant">
      <NavUl>{navLinks}</NavUl>
    </Nav>
  )
}

export default Navigation
